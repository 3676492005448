import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';
import { get, post } from 'axios';
import { Container, Grid, Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PageViewIcon from '@material-ui/icons/Pageview';
import Title from '../common/Title';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssessmentIcon from '@material-ui/icons/Assessment';

const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

class PackageOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loadFinished: false,
          packages: [],
          loadSuccessful: true,
          creationFinished: false,
          creationSuccessful: false,
          creationMessages: [],
          snackbar: false,
          snackbarMessage: ''
        };
      }

    loadPackages() {
        get(process.env.REACT_APP_IDES_API_URL, { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
            .then(response => {
                this.setState({
                    loadFinished: true,
                    packages: response.data,
                    loadSuccessful: true
                });
            })
            .catch(error => {
                this.setState({
                    loadFinished: true,
                    packages: [],
                    loadSuccessful: false,
                    snackbar: true,
                    snackbarMessage: error.message
                });
                console.error("Failed fetching IDES packages list from API call");
            });
    }

    componentDidMount() {
        this.loadPackages();
    }

    selectYear = (key, e) => {
        sessionStorage.setItem("selectedYear", key);
    }

    closeSnackBar = e => {
        this.setState({
            snackbar: false
        });
    }

    render() {
        const { classes } = this.props;

        const dateTimeOptions = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false
          };

        return (
            <React.Fragment>
                <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Title id="form-dialog-title">Преглед креираних IDES пакета</Title>
                    <Typography component="p" variant="body1" style={{ paddingTop: 20 }}>
                        У табели су прикажани креирани IDES пакети. Сваки пакет одговара једном извештају.
                    </Typography>
                    <Grid container spacing={3} style={{padding: 30}} />
                    <Table size="small">
                        <TableHead>
                        <TableRow>
                           <TableCell align="center">Датум израде пакета</TableCell>
                            <TableCell align="center">Назив датотеке</TableCell>
                            <TableCell align="center">Статус</TableCell>
                            <TableCell align="center">Детаљи извештаја</TableCell>
                            <TableCell align="center">Детаљи нотификације</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.state.packages.map( row => (
                            <TableRow key={row.year}>
                                <TableCell align="center">
                                  <div>
                                  {Intl.DateTimeFormat('sr-Latn-RS', dateTimeOptions).format(new Date(row.dateCreated)) }
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  { row.documentId }
                                </TableCell>
                                <TableCell align="center">
                                  { row.status }
                                </TableCell>
                                <TableCell align="center">
                                      <AssessmentIcon/>
                                </TableCell>
                                <TableCell align="center">
                                  <NotificationsIcon/>
                                </TableCell>

                            </TableRow>
                          ))}
                        </TableBody>
                    </Table>
                    { !this.state.loadFinished ?
                    <Grid container spacing={3} style={{padding: 40}}>
                      <Grid item xs={12}>
                        <div align="center">
                          <CircularProgress />
                          <Typography component="p" variant="body1" color="primary">
                          Подаци се учитавају
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                      : ""}
                    </Paper>
                </Grid>
            </Grid>
            </Container>
            <Snackbar open={this.state.snackbar}
                autoHideDuration={5000}
                anchorOrigin={{ vertical:'bottom', horizontal:'right'}}
                onClose={this.closeSnackBar} >
                { !this.state.loadSuccessful ?
                <Alert severity="error" onClose={this.closeSnackBar}>
                    <AlertTitle>Грешка при учитавању листе извештаја за ваш профил</AlertTitle>
                    {this.state.snackbarMessage}
                </Alert>
                :
                (this.state.creationFinished && this.state.creationSuccessful ?
                <Alert severity="success" onClose={this.closeSnackBar} style={{whiteSpace: 'pre-line'}}>
                    <AlertTitle>Успешно креиран IDES пакет за одабрану годину</AlertTitle>
                    { this.state.creationMessages.map((message) => (
                        message.text + "\n"
                    ))}
                </Alert>
                :
                (this.state.creationFinished && !this.state.creationSuccessful ?
                <Alert severity="error" onClose={this.closeSnackBar} style={{whiteSpace: 'pre-line'}}>
                    <AlertTitle>Грешка при креирању пакета за одабрану годину</AlertTitle>
                    { this.state.creationMessages.map((message) => (
                        message.text + "\n"
                    ))}
                </Alert>
                : " ")
                )}
            </Snackbar>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(PackageOverview);
