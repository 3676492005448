import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';
import { Container, Grid, Paper, Button, DialogActions, Typography, TextField, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PublishIcon  from '@material-ui/icons/Publish';
import Title from '../common/Title';
import { post } from 'axios';

const filenameRegex = new RegExp("^[A-Z0-9]{6}[.][0-9]{5}[.][A-Z]{2}[.][0-9]{3}_20[1-9][0-9]_[0-9]{1,}[.]zip");

const useStyles = theme => ({
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
});

class ReportUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            fileName: ' ',
            fileNameError: null,
            giin: ' ',
            giinError: null,
            year: ' ',
            yearError: null,
            number: ' ',
            validArchive: false,
            fileUploaded: true,
            fileUploadSuccessful: null,
            fileUploadMessages: [],
            snackbar: false
        };
    }

    onChange(e) {
        if (e.target.files[0] !== undefined) {
            let filename = e.target.files[0].name;

            if (this.validateFileName(filename)) {
                let fileItems = e.target.files[0].name.split("_");

                this.setState({
                    file: e.target.files[0],
                    fileName: filename,
                    giin: fileItems[0],
                    year: fileItems[1],
                    number: fileItems[2].split(".")[0],
                    validArchive: true,
                    fileNameError: null,
                    giinError: null,
                    yearError: null, 
                    fileUploadSuccessful: null,
                    fileUploadMessages: []
                });

                if (!sessionStorage.getItem("giin").includes(fileItems[0])) {
                    this.setState({
                        giinError: "GIIN идентификатор се не поклапа са вашим профилом",
                        validArchive: false
                    })
                }
                
                if (fileItems[1] < 2014) {
                    this.setState({
                        yearError: "Могуће је поднети извештаје почев од 2014. године",
                        validArchive: false
                    })
                }

            } else {
                this.setState({
                    fileName: filename,
                    fileNameError: "Назив датотеке није исправан",
                    validArchive: false
                });
            }

        }
    }

    validateFileName(filename) {
        return filenameRegex.test(filename);
    }

    closeSnackBar = e => {
        this.setState({
            snackbar: false
        });
    }

    submitFile() {
        this.setState({ 
            fileUploaded: false
         });
        let fileReader = new FileReader();

        fileReader.readAsDataURL(this.state.file);
        fileReader.onload = (e) => {
            // Create an object of formData 
            const formData = new FormData(); 
            
            // Update the formData object 
            formData.append( 
                "uploadedFile", 
                this.state.file, 
                this.state.fileName
            ); 
            return post(process.env.REACT_APP_API_URL, formData, { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
                .then(response => {
                    let message = "Извештај успешно послат - " + response.data.name;
                    this.setState({ 
                        file: null,
                        fileUploaded: true,
                        fileUploadSuccessful: true,
                        fileUploadMessages: [{"text": message}],
                        validArchive: true,
                        fileName: ' ',
                        giin: ' ',
                        year: ' ',
                        number: ' ',
                        snackbar: true
                     });
                })
                .catch(error => {
                    this.setState({ 
                        file: null,
                        fileUploaded: true,
                        fileUploadSuccessful: false,
                        validArchive: true,
                        fileName: ' ',
                        giin: ' ',
                        year: ' ',
                        number: ' ',
                        snackbar: true
                    });
                    if (error.response) {
                        this.setState({
                            fileUploadMessages: error.response.data,
                        });
                        console.error(error.response.status + " - " + error.response.data);
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error ', error.message);
                        this.setState({ 
                            fileUploadMessages: [{"text": error.message}]
                        });
                    }
                    
                });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Title id="form-dialog-title">Слање FATCA извештаја</Title>
                                <Typography component="p" variant="body1" style={{paddingTop: 20}}>
                                    Прописани формат за достављање FATCA извештаја је XML у складу са достављеним XSD шемама. Исти је неопходно запаковати
                                    у оквиру ZIP архиве, пратећи дефнисана правила именовања извештаја и архиве.
                                </Typography>
                                <Container maxWidth="lg" style={{padding: 30}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField fullWidth
                                                id="financialInstitution"
                                                label="Финансијска институција"
                                                value={sessionStorage.getItem("company")}
                                                disabled
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField fullWidth
                                                id="selectedFile"
                                                label="Одабрани документ"
                                                value={ this.state.fileName }
                                                error={ !!this.state.fileNameError }
                                                helperText={ this.state.fileNameError }
                                                disabled
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container maxWidth="lg" >
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField fullWidth
                                                id="giin"
                                                label="GIIN идентификатор"
                                                value={ this.state.giin }
                                                error={ !!this.state.giinError }
                                                helperText={ this.state.giinError }
                                                disabled
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField fullWidth
                                                id="year"
                                                label="Година извештаја"
                                                value={ this.state.year }
                                                error={ !!this.state.yearError }
                                                helperText={ this.state.yearError }
                                                disabled
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField fullWidth
                                                id="number"
                                                label="Редни број извештаја у години"
                                                value={ this.state.number }
                                                disabled
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container maxWidth="lg" style={{padding: 30}}>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <Button onClick={() => this.fileUpload.click()} variant="contained" color="primary">
                                            <PublishIcon className="material-icons" />
                                            Одабери документ
                                        </Button>
                                    </Grid>
                                    <input type="file" ref={(fileUpload) => {
                                            this.fileUpload = fileUpload;
                                        }} style={{ visibility: 'hidden'}} onChange={(e) => this.onChange(e)} />
                                </Container>
                                <Grid container spacing={3}>
                                <Grid item xs={12}>
                                { !this.state.fileUploaded ?
                                    <div align="center">
                                        <CircularProgress />
                                        <Typography component="p" variant="body1" color="primary"> 
                                        Извештај се шаље
                                        </Typography>  
                                    </div> 
                                    : ""
                                }
                                </Grid>
                                </Grid>
                                    
                                <DialogActions>
                                    <Button component={Link} to="/" color="primary">
                                        Одустани
                                    </Button>
                                    <Button onClick={(e) => this.submitFile()} color="primary" disabled={ !this.state.validArchive } >
                                        Пошаљи
                                    </Button>
                                </DialogActions>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Snackbar open={this.state.snackbar} 
                    anchorOrigin={{ vertical:'bottom', horizontal:'right'}}
                    onClose={this.closeSnackBar} >
                { this.state.fileUploadSuccessful ?
                    <Alert severity="success" onClose={this.closeSnackBar} style={{whiteSpace: 'pre-line'}}>
                        <AlertTitle>Извештај успешно послат</AlertTitle>
                        { this.state.fileUploadMessages.map((message) => (
                            message.text + "\n"
                        ))}
                    </Alert>
                    :
                    <Alert severity="error" onClose={this.closeSnackBar} style={{whiteSpace: 'pre-line'}}>
                        <AlertTitle>Грешка при слању извештаја</AlertTitle>
                        { this.state.fileUploadMessages.map((message) => (
                            message.text + "\n"
                        ))}
                    </Alert>
                }
                </Snackbar>
            </React.Fragment>
        )
    }

}

export default withStyles(useStyles)(ReportUpload);