import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';
import { get } from 'axios';
import { Container } from '@material-ui/core';
import { Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, TableCell, Grid, Paper, TextField, CircularProgress, Typography, Snackbar, IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PageViewIcon from '@material-ui/icons/Pageview';
import Title from '../common/Title';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },

}));




function TablePaginationActions(props) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onPageChange } = props;


  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };



return (
  <div className={classes.root}>
    <IconButton
      onClick={handleFirstPageButtonClick}
      disabled={page === 0}
      aria-label="first page"
    >
      <FirstPageIcon />
    </IconButton>
    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
      <KeyboardArrowLeft />
    </IconButton>
    <IconButton
      onClick={handleNextButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="next page"
    >
      <KeyboardArrowRight />
    </IconButton>
    <IconButton
      onClick={handleLastPageButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="last page"
    >
      <LastPageIcon />
    </IconButton>
  </div>
);
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
   margin: theme.spacing(1),
   minWidth: 120,
  },
  selectEmpty: {
   marginTop: theme.spacing(2),
  },
});




class ReportList extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      loadFinished: false,
      count: 0,
      pageSize: 10,
      page: 0,
      reports: [],
      groups: [],
      loadSuccessful: true,
      snackbar: false,
      snackbarMessage: '',
      org: "",
      status: "",

    };
  }


  loadGroups() {
    get(process.env.REACT_APP_API_URL + "/groups", { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
    .then(response => {
      this.setState({
        groups: response.data
      });
    })
    .catch(error => {
      this.setState({
        loadFinished: true,
        loadSuccessful: false,
        snackbar: true,
        snackbarMessage: error.message
      });
      console.error("Failed fetching Groups through API call");
    });

  }


  loadReports(page, pageSize, org, status) {
    get(process.env.REACT_APP_API_URL + "?page=" + page + "&pageSize=" + pageSize + "&org=" + org + "&status=" + status, { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
    .then(response => {
      this.setState({
        loadFinished: true,
        reports: response.data.reports,
        count: response.data.count,
        page: response.data.page,
        pageSize: response.data.pageSize,
        loadSuccessful: true,
        snackbar: false,
      });
    })
    .catch(error => {
      this.setState({
        loadFinished: true,
        reports: [],
        loadSuccessful: false,
        snackbar: true,
        snackbarMessage: error.message
      });
      console.error("Failed fetching FATCA reports collection through API call");
    });
  }

  componentDidMount() {
  //  console.log("Radim componentDidMount");
  //  console.log("Status je: " + sessionStorage.getItem("status"));
  //  console.log("Org je: " +  sessionStorage.getItem("org"));
  //  console.log("Page je: " +  sessionStorage.getItem("page"));
  //  console.log("detailsBack je: " +  sessionStorage.getItem("detailsBack"));
    this.loadGroups();
    //this.loadReports(this.state.page, this.state.pageSize, this.state.org, this.state.status);
    this.setState({
        status: sessionStorage.getItem("status"),
        org: sessionStorage.getItem("org")
      }
    );
    this.loadReports((sessionStorage.getItem("detailsBack") === "true") ? sessionStorage.getItem("page") : this.state.page, this.state.pageSize, sessionStorage.getItem("org"), sessionStorage.getItem("status"));
    sessionStorage.setItem("detailsBack",false);

  }

  selectReport = (key, e) => {
    sessionStorage.setItem("selectedReport", key);
    sessionStorage.setItem("page", this.state.page);
    sessionStorage.setItem("detailsBack", true);
  }

  changePage = (event, newPage) => {
    console.log("ChangePage status: " + this.state.status);
    console.log("ChangePage org: " + this.state.org);
    this.loadReports(newPage, this.state.pageSize, this.state.org, this.state.status);
  }

  closeSnackBar = e => {
    this.setState({
        snackbar: false
    });
  }

  render() {
    const { classes } = this.props;

    const handleOrgChange = (event) => {
      this.setState(
        {
          org: event.target.value,
        }
      );
      sessionStorage.setItem("org", event.target.value);
      this.loadReports(0,10, event.target.value, sessionStorage.getItem("status"));
    };

    const handleStatusChange = (event) => {
      this.setState(
        {
          status: event.target.value,
        }
      );
      sessionStorage.setItem("status", event.target.value);
      this.loadReports(0,10, sessionStorage.getItem("org"), event.target.value);
    };

    const dateTimeOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    };






    return (
      <React.Fragment>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Достављени FATCA извештаји</Title>
                <Container maxWidth="lg" style={{padding: 20}}>
                  <Grid container spacing={3} style={{paddingBottom: 30}}>
                      <Grid item xs={12}>
                          <TextField fullWidth
                              id="financialInstitution"
                              label="Финансијска институција"
                              value={sessionStorage.getItem("company")}
                              disabled
                              variant="outlined"
                          />
                      </Grid>
                  </Grid>
                  { (sessionStorage.getItem('isAdmin') !== "true") ? " " :
                  <FormControl className={classes.formControl}>
                    <InputLabel id="orgSelect">Организација</InputLabel>
                    <Select
                      labelId="orgSelect"
                      id="orgSelect"
                      value={sessionStorage.getItem("org")}
                      onChange={handleOrgChange}
                    >
                    <MenuItem value="%25">
                      <em>-</em>
                    </MenuItem>
                      {this.state.groups.map((group) => (
                          <MenuItem value={group.giin}>{group.company}</MenuItem>

                      )
                      )}
                    </Select>
                  </FormControl>
                  }

                  <FormControl className={classes.formControl}>
                    <InputLabel id="statusSelect">Статус</InputLabel>
                    <Select
                      labelId="statusSelect"
                      id="statusSelect"
                      value={sessionStorage.getItem("status")}
                      onChange={handleStatusChange}
                    >
                    <MenuItem value="%25">
                      <em>-</em>
                    </MenuItem>
                      <MenuItem value="SENT_TO_APML">SENT_TO_APML</MenuItem>
                      <MenuItem value="ACCEPTED_BY_APML">ACCEPTED_BY_APML</MenuItem>
                      <MenuItem value="REJECTED_BY_APML">REJECTED_BY_APML</MenuItem>
                      <MenuItem value="SENT_TO_IRS">SENT_TO_IRS</MenuItem>
                      <MenuItem value="ACCEPTED_BY_IRS">ACCEPTED_BY_IRS</MenuItem>
                      <MenuItem value="REJECTED_BY_IRS">REJECTED_BY_IRS</MenuItem>
                      <MenuItem value="FATCA_ELM_NOT_FOUND">FATCA_ELM_NOT_FOUND</MenuItem>
                      <MenuItem value="HOLD_BY_APML">HOLD_BY_APML</MenuItem>
                    </Select>
                  </FormControl>

                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Датум и време</TableCell>
                        <TableCell align="center">Корисник</TableCell>
                        <TableCell align="center">Назив датотеке</TableCell>
                        <TableCell align="center">Година</TableCell>
                        <TableCell align="center">Редни број</TableCell>
                        <TableCell align="center">Статус</TableCell>
                        <TableCell align="center">Детаљи</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.reports.map((row) => (
                        <TableRow key={row.id} hover="true">
                            <TableCell align="center">
                                <div>
                                  { Intl.DateTimeFormat('sr-Latn-RS', dateTimeOptions).format(new Date(row.dateCreated)) }
                                </div>
                            </TableCell>
                            <TableCell>{row.userCreated}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="center">{row.year}</TableCell>
                            <TableCell align="center">{row.reportNumber}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center">
                            <IconButton onClick={this.selectReport.bind(this, row.id)} component={ Link } to="reportDetails">
                              <PageViewIcon color="primary" />
                            </IconButton>
                            </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[]}
                          colSpan={7}
                          count={this.state.count}
                          rowsPerPage={this.state.pageSize}
                          page={this.state.page}
                          onChangePage={this.changePage}
                          labelDisplayedRows={
                            ({ from, to, count }) => {
                              return 'Редови ' + from + ' - ' + to + ' од ' + count
                            }
                          }
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  { !this.state.loadFinished ?
                    <Grid container spacing={3} style={{padding: 40}}>
                      <Grid item xs={12}>
                        <div align="center">
                          <CircularProgress />
                          <Typography component="p" variant="body1" color="primary">
                          Подаци се учитавају
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                      : ""}
                    { this.state.loadFinished && this.state.reports.length === 0 ?
                    <Grid container spacing={3} style={{padding: 30}}>
                      <Grid item xs={12}>
                        <div align="center">
                          <Typography component="p" variant="body2" color="primary">
                          Нема послатих FATCA извештаја заведених у систему за вашу финансијску институцију
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                      : ""}
                </Container>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        { this.state.loadSuccessful ? " " :
        <Snackbar open={this.state.snackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical:'bottom', horizontal:'right'}}
            onClose={this.closeSnackBar} >
            <Alert severity="error" onClose={this.closeSnackBar}>
                <AlertTitle>Грешка при учитавању листе извештаја за ваш профил</AlertTitle>
                {this.state.snackbarMessage}
            </Alert>
        </Snackbar>
        }
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(ReportList);
