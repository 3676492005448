import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const Profile = React.forwardRef( ({props}, ref) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const spanRef = React.useRef();

  return (
    <div ref={ref}>
      <span ref={spanRef}>
      <MenuItem onClick={handleClickOpen}>Профил</MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Кориснички профил</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Table size="small" style={{width: 550}}>
            <TableBody>
              <TableRow>
                  <TableCell>Корисник</TableCell>
                  <TableCell>{sessionStorage.getItem("user")}</TableCell>
              </TableRow>   
              <TableRow>
                  <TableCell>Е-маил</TableCell>
                  <TableCell>{sessionStorage.getItem("email")}</TableCell>
              </TableRow> 
              <TableRow>
                  <TableCell>Компанија</TableCell>
                  <TableCell>{sessionStorage.getItem("company")}</TableCell>
              </TableRow> 
              <TableRow>
                  <TableCell>GIIN</TableCell>
                  <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{sessionStorage.getItem("giin")}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Профил</TableCell>
                  <TableCell>{(sessionStorage.getItem("isAdmin") === "true") ? "Администратор" : "Корисник"}</TableCell>
              </TableRow>         
            </TableBody>
          </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Затвори
          </Button>
        </DialogActions>
      </Dialog>
      </span>
    </div>
  );
}

);

export default Profile;