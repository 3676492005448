import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { List, Divider, Drawer, CssBaseline, ListItem, ListItemIcon, Typography, Box } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PublishIcon from '@material-ui/icons/Publish';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Logo from '../../images/mfin_logo.svg';
import bgImage from "../../images/sidebar.png";
import {boxShadow} from "../../components/common/material-dashboard-react.js";

const drawerWidth = 240;

const alignStyle = {
  align: 'center'
}


const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      zIndex: "1",
      width: drawerWidth,
      ...boxShadow,
      backgroundColor: '#232f3e',
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    nested: {
        paddingLeft: theme.spacing(2)
    },
    menuicon: {
        padding: theme.spacing(0.5),
    },
    imagestyle: {
        align: 'center',
        width: '100%',
    },
    menuText: {
      color: "#aaaaaa",
    },
    divider: {
     background: "#888888",
    }
  }));


export default function SideBar() {
    const classes = useStyles();

    const [open] = React.useState(true);

    return (
        <React.Fragment>
            <CssBaseline />
            <Drawer variant="permanent" classes={{ paper: clsx(classes.drawerPaper) }} open={open}>
                <Box align={ alignStyle.align }>
                  <img src={Logo} alt="USPN Logo" classes={ classes.imagestyle } />
                </Box>
                <Typography component="p" variant="subtitle2" noWrap align="center" className={ classes.menuText } style={{paddingTop: 20}} >Управа за спречавање</Typography>
                <Typography component="p" variant="subtitle2" noWrap align="center" className={ classes.menuText } style={{paddingBottom: 20}}>прања новца</Typography>
                <Divider className={ classes.divider }/>
                <List>
                    <div>
                        <ListItem button component={Link} to="/" selected={!window.location.pathname.includes("report") && !window.location.pathname.includes("package")} className={ classes.menuText }>
                            <ListItemIcon>
                                <HomeIcon className={ classes.menuText } />
                            </ListItemIcon>
                                FATCA
                        </ListItem>
                        <List className={classes.nested} disablePadding>
                            <ListItem button component={Link} to="reportUpload" selected={window.location.pathname.includes("reportUpload")} className={ classes.menuText }>
                                <ListItemIcon>
                                    <PublishIcon className={ classes.menuText } />
                                </ListItemIcon>
                                Слање извештаја
                            </ListItem>
                            <ListItem button component={Link} to="reports" selected={window.location.pathname.includes("reports") || window.location.pathname.includes("reportDetails")} className={ classes.menuText }>
                                <ListItemIcon>
                                    <DashboardIcon className={ classes.menuText }/>
                                </ListItemIcon>
                                Преглед извештаја
                            </ListItem>
                        </List>
                        { (sessionStorage.getItem('isAdmin') !== "true") ? " " :
                        <div>
                            <ListItem>
                            </ListItem>
                            <Divider className={ classes.divider }></Divider>
                            <ListItem>
                            </ListItem>
                            <ListItem className={ classes.menuText }>
                                <ListItemIcon>
                                    <AccountBalanceIcon className={ classes.menuText }/>
                                </ListItemIcon>
                                    IDES
                            </ListItem>
                            <List className={classes.nested} disablePadding>
                                <ListItem button component={Link} to="packageOverview" selected={window.location.pathname.includes("packageOverview")} className={ classes.menuText }>
                                    <ListItemIcon>
                                        <DynamicFeed className={ classes.menuText } />
                                    </ListItemIcon>
                                    Послати пакети
                                </ListItem>
                            </List>
                        </div>
                        }
                    </div>
                </List>
                <Divider className={ classes.divider }/>
            </Drawer>
        </React.Fragment>
    );
}
