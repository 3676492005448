import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { get } from 'axios';
import { Container, TextField, Grid, Table, TableHead, TableRow, TableBody, TableCell, Button, Paper, CircularProgress, Typography, Snackbar, IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import DownloadIcon  from '@material-ui/icons/GetApp';
import Title from '../common/Title';
import PageViewIcon from '@material-ui/icons/Pageview';
import NotificationModal from './NotificationModal.js';
import { Link } from 'react-router-dom';

const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

class ReportDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadFinished: false,
            notificationModalIsOpen: false,
            notificatioModalId: 0,
            report: {
                id: ' ',
                name: ' ',
                dateCreated: ' ',
                giin: ' ',
                year: ' ',
                reportNumber: ' ',
                userCreated: ' ',
                status: 1
            },
            notifications: [],
            loadSuccessful: true,
            snackbar: false,
            notificationKey: 'default',
            notificationsRecError: [],
            status: "",
            org: "",
        };

    }

    openNotificationModal(id) {
      console.log("Notifikacija: " + id);

      get(process.env.REACT_APP_API_URL + "/notifications/recerror/" + id, { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
      .then(response => {
        console.log(response);
      //  console.log(response.data);
        this.setState({
          notificationsRecError: response.data
        });
        //console.log(this.state.notifications.length);

      })
      .catch(error => {
          this.setState({
              //loadFinished: true,
              //loadSuccessful: false,
              snackbar: true
            });
        console.error("Failed fetching FATCA report notifications rec errors through API call");
      });

      this.setState({
         notificationModalId: id,
         notificationModalIsOpen: true});
    };

    closeNotificationModal() {
      this.setState({notificationModalIsOpen: false});
    };

    componentDidMount() {
        get(process.env.REACT_APP_API_URL + "/" + sessionStorage.getItem("selectedReport"), { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
        .then(response => {
          this.setState({
            loadFinished: true,
            loadSuccessful: true,
            report: response.data
          });
        })
        .catch(error => {
            this.setState({
                loadFinished: true,
                loadSuccessful: false,
                snackbar: true
              });
          console.error("Failed fetching FATCA report by ID through API call");
        });

        get(process.env.REACT_APP_API_URL + "/" + sessionStorage.getItem("selectedReport") + "/notifications", { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
        .then(response => {
        //  console.log(response);
        //  console.log(response.data);
          this.setState({
            loadFinished: true,
            loadSuccessful: true,
            notifications: response.data
          });
          //console.log(this.state.notifications.length);

        })
        .catch(error => {
            this.setState({
                loadFinished: true,
                loadSuccessful: false,
                snackbar: true
              });
          console.error("Failed fetching FATCA report notifications through API call");
        });

    }

    closeSnackBar = e => {
        this.setState({
            snackbar: false
        });
    }



    downloadFile = (e) => {
        e.preventDefault();

        get(process.env.REACT_APP_API_URL + "/" + sessionStorage.getItem("selectedReport") + '/file', { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data],{type: 'application/xml'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.state.report.name);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            this.setState({
                snackbar: true,
                snackbarMessage: error.message
            });
            console.error("Failed fetching FATCA report file by ID through API call");
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                {/* FATCA reports */}
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Title>Детаљи послатог извештаја</Title>
                    <Container maxWidth="lg" style={{padding: 30}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    id="financialInstitution"
                                    label="Финансијска институција"
                                    value={sessionStorage.getItem("company")}
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                    id="reportName"
                                    label="Назив датотеке"
                                    value={ this.state.report.name }
                                    disabled
                                    variant="outlined"
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth
                                    id="dateSubmitted"
                                    label="Датум и време пријема"
                                    value={ (new Date(this.state.report.dateCreated)).toLocaleDateString() + " " + (new Date(this.state.report.dateCreated)).toLocaleTimeString() }
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <TextField fullWidth
                                    id="year"
                                    label="Година"
                                    value={ this.state.report.year }
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth
                                    id="number"
                                    label="Редни број"
                                    value={ this.state.report.reportNumber }
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth
                                    id="user"
                                    label="Корисник"
                                    value={ this.state.report.userCreated }
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth
                                    id="status"
                                    label="Статус"
                                    value={ this.state.report.status }
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        </Container>

                        <Button onClick={ this.downloadFile } color="primary" style={{padding: 30}}>
                            <DownloadIcon className="material-icons" style={{marginRight: 10}}/>
                            Преузми извештај
                        </Button>

                        <Title>Нотификације</Title>
                        <Table size="medium">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center">Датум и време</TableCell>
                                <TableCell align="center">Назив датотеке</TableCell>
                                <TableCell align="center">Тип нотификације</TableCell>
                                <TableCell align="center">Oпис</TableCell>
                                <TableCell align="center">Детаљи</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.notifications.map((notification) => (
                                <TableRow key={notification.id}>
                                    <TableCell align="center">{ (new Date(notification.dateCreated)).toLocaleDateString() + " " + (new Date(notification.dateCreated)).toLocaleTimeString() }</TableCell>
                                    <TableCell>{notification.fileName}</TableCell>
                                    <TableCell align="center">{notification.notificationCode}</TableCell>
                                    <TableCell align="center">{notification.notificationContentTxt}</TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={this.openNotificationModal.bind(this,notification.id)} >
                                          <PageViewIcon color="primary" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}

                            </TableBody>
                        </Table>




                        <NotificationModal notificationModalIsOpen={this.state.notificationModalIsOpen} notificationCloseModal={this.closeNotificationModal.bind(this)} notificationModalId={this.state.notificationModalId} notificationsRecError={this.state.notificationsRecError}/>



                        { !this.state.loadFinished ?
                        <Grid container spacing={3} style={{padding: 40}}>
                        <Grid item xs={12}>
                            <div align="center">
                            <CircularProgress />
                            <Typography component="p" variant="body1" color="primary">
                            Подаци се учитавају
                            </Typography>
                            </div>
                        </Grid>
                        </Grid>

                        : ""}
                        { this.state.loadFinished && this.state.notifications.length === 0 ?


                        <Grid container spacing={3} style={{padding: 30}}>
                        <Grid item xs={12}>
                            <div align="center">
                            <Typography component="p" variant="body2" color="primary">
                            Нема пристиглих нотификација за изабрани FATCA извештај
                            </Typography>
                            </div>
                        </Grid>
                        </Grid>
                        : ""}

                        <Grid container spacing={3} style={{padding: 10}}>
                          <Grid item xs={1}>
                            <Button variant="contained" color="primary" style={{padding: 10}} size="small" component={ Link } to="reports">
                              Повратак
                            </Button>
                          </Grid>
                        </Grid>
                    </Paper>

                </Grid>
                </Grid>
            </Container>
            { this.state.loadSuccessful ? " " :
            <Snackbar open={this.state.snackbar}
                autoHideDuration={5000}
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                onClose={this.closeSnackBar} >
                <Alert severity="error" onClose={this.closeSnackBar}>
                    <AlertTitle>Грешка при учитавању детаља одабраног извештаја</AlertTitle>
                    {this.state.fileUploadMessage}
                </Alert>
            </Snackbar>
            }
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(ReportDetails);
