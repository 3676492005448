import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Title from './components/common/Title';
import Keycloak from 'keycloak-js';

//Get the keycloak configuration
let keycloak = Keycloak({
  "realm": process.env.REACT_APP_KEYCLOAK_REALM,
  "url": process.env.REACT_APP_KEYCLOAK_URL,
  "ssl-required": "external",
  "clientId": process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  "public-client": true,
  "confidential-port": 0
});
 
//Initialization of the keycloak instance
keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
 
  // Load the roles from KeyCloak
  let hasUserRole = keycloak.tokenParsed.realm_access.roles.indexOf('uspn-fatca-user') > -1;
  let hasAdminRole = keycloak.tokenParsed.realm_access.roles.indexOf('uspn-fatca-admin') > -1;

  if (!authenticated || (!hasUserRole && !hasAdminRole)) {
    //React Render on authentication
    ReactDOM.render(
      <React.StrictMode>
        <Title>Not Authorized</Title>
      </React.StrictMode>,
      document.getElementById('root')
    );
  } else {
    //Store authentication tokens in sessionStorage for usage in app
    sessionStorage.setItem('authentication', keycloak.token);
    sessionStorage.setItem('user', keycloak.tokenParsed.name);
    sessionStorage.setItem('giin', keycloak.tokenParsed.giin);
    sessionStorage.setItem('company', keycloak.tokenParsed.company);
    sessionStorage.setItem('email', keycloak.tokenParsed.email);
    sessionStorage.setItem('isUser', hasUserRole);
    sessionStorage.setItem('isAdmin', hasAdminRole);

    //React Render on authentication
    ReactDOM.render(
      <React.StrictMode>
        <App keycloak={keycloak}/>
      </React.StrictMode>,
      document.getElementById('root')
    );

    //to regenerate token on expiry
    setInterval(() => {
          keycloak.updateToken(70).then((refreshed) => {
              if (refreshed) {
                  console.info('Token refreshed - ' + refreshed);
                  sessionStorage.setItem('authentication', keycloak.token);
              } else {
                  console.warn('Token not refreshed, valid for '
                      + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
              }
          }).catch(() => {
              console.error('Failed to refresh token');
          });    
      }, 240000);

  }
  
  }).catch(() => {
    console.error("Authenticated Failed");
  });