import React from 'react';
import Modal from 'react-modal';
import Title from '../common/Title';
import { Container, Table, TableHead, TableRow, TableBody, TableCell, Button } from '@material-ui/core';

Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1000

  },
  content: {
    top: '20%',
    left: '15%',
    right: 'auto',
    bottom: 'auto',
    width: '75%',
    height: '75%',
    background: '#fff',
    overflow: 'auto',
    transparent: false,
    //marginRight: '-50%',
    //transform: 'translate(-50%, -50%)',
  },
};

class NotificationModal extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      notificationsRecError: [],


    };
  }




  render() {
   return (
     <Modal
       isOpen={this.props.notificationModalIsOpen}
       onRequestClose={this.props.notificationCloseModal}
       style={customStyles}
       contentLabel="Notification details"
       centered
     >
       <div className="modal-wrapper">
         <div className="container text-center">
            <Title>Детаљи грешака у подацима</Title>
            <Table size="medium">
                <TableHead>
                <TableRow>
                    <TableCell align="center">Име извештајне институције</TableCell>
                    <TableCell align="center">DOCREFID</TableCell>
                    <TableCell align="center">Захтевана акција</TableCell>
                    <TableCell align="center">Име поља</TableCell>
                    <TableCell align="center">Грешка поља</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.props.notificationsRecError.map((recError) => (
                    <TableRow key={recError.id}>
                        <TableCell align="center">{recError.reportingFiName}</TableCell>
                        <TableCell align="center">{recError.docRefId}</TableCell>
                        <TableCell>{recError.actionRequestedTxt}</TableCell>
                        <TableCell align="center">{recError.fieldNm}</TableCell>
                        <TableCell align="center">{recError.fieldErrorTxt}</TableCell>
                    </TableRow>
                ))}

                </TableBody>
            </Table>
            <br/>


         </div>
       </div>

           <Button onClick={this.props.notificationCloseModal} variant="contained" color="primary" style={{padding: 10}} centered>
             Затвори
           </Button>
    
     </Modal>
   )
 }
};


export default NotificationModal;
