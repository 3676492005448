import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ReportList from './components/fatcaReports/ReportList';
import ReportDetails from './components/fatcaReports/ReportDetails';
import ReportUpload from './components/fatcaReports/ReportUpload';
import PackageOverview from './components/idesPackages/PackageOverview';
import PackageList from './components/idesPackages/PackageList';
import TopBar from './components/navigation/TopBar';
import SideBar from './components/navigation/SideBar';
import FatcaDashboard from './components/fatcaReports/FatcaDashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));

export default function App(props) {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.root}>

        <Route path="/" render={() => <TopBar keycloak={props.keycloak} />}/>
        <Route path="/" component={SideBar} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Route exact path="/" component={FatcaDashboard} />
          <Route exact path="/reports" component={ReportList} />
          <Route exact path="/reportDetails" component={ReportDetails} />
          <Route exact path="/reportUpload" component={ReportUpload} />
          <Route exact path="/packageOverview" component={PackageOverview} />
          <Route exact path="/packageList" component={PackageList} />
        </main>

      </div>
    </Router>
  );
}
