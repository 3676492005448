import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { get } from 'axios';
import { Container, Grid, Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import DownloadIcon from '@material-ui/icons/GetApp';
import Title from '../common/Title';

  
const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

class PackageList extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          loadFinished: false,
          packages: [],
          loadSuccessful: true,
          creationFinished: false,
          creationSuccessful: false,
          creationMessages: [],
          snackbar: false,
          snackbarMessage: ''
        };
      }

    loadPackages() {
        get(process.env.REACT_APP_IDES_API_URL + "/" + sessionStorage.getItem("selectedYear"), { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
            .then(response => {
                this.setState({
                    loadFinished: true,
                    packages: response.data,
                    loadSuccessful: true
                });
            })
            .catch(error => {
                this.setState({
                    loadFinished: true,
                    packages: [],
                    loadSuccessful: false,
                    snackbar: true,
                    snackbarMessage: error.message
                });
                console.error("Failed fetching IDES packages list for selected year from API call");
            });
    }

    componentDidMount() {
        this.loadPackages();
    }

    downloadPackage = key => {
        get(process.env.REACT_APP_IDES_API_URL + "/" + key + '/file', { 
            responseType: 'arraybuffer',    
            headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } 
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data],{type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', key + '.zip');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            this.setState({
                snackbar: true,
                snackbarMessage: error.message
            });
            console.error("Failed fetching IDES package by ID through API call");
        });
    }

    closeSnackBar = e => {
        this.setState({
            snackbar: false
        });
    }
    
    render() {
        const { classes } = this.props;

        const dateTimeOptions = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false
          };

        return (
            <React.Fragment>
                <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Title id="form-dialog-title">Преглед креираних IDES пакета за годину - {sessionStorage.getItem("selectedYear")} </Title>
                    <Typography component="p" variant="body1" style={{ paddingTop: 20 }}>
                        У табели испод су приказани до сада креирани пакети за слање на IDES портал, а за одабрану годину.<br/>
                        Пакети садрже информације о времену креирања, тренутном статусу, односно броју извештаја који су
                        постојали у систему у тренутку креирања пакета. Такође селекцијом иконе за преузимање истих, можете
                        скинути сваки од пакета и извршити њихово слање путем IDES портала.
                    </Typography>
                    <Grid container spacing={3} style={{padding: 30}} />
                    <Table size="small">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Пакет</TableCell>
                            <TableCell align="center">Статус</TableCell>
                            <TableCell align="center">Датум израде пакета</TableCell>
                            <TableCell align="center">Број извештаја у пакету</TableCell>
                            <TableCell align="center">Корисник</TableCell>
                            <TableCell align="center">Преузимање</TableCell>                        
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.state.packages.map((row) => (
                            <TableRow key={row.id}>                                 
                                <TableCell align="center">{row.documentId}</TableCell>
                                <TableCell align="center">{row.status}</TableCell>
                                <TableCell align="center">
                                    <div>
                                        { Intl.DateTimeFormat('sr-Latn-RS', dateTimeOptions).format(new Date(row.dateCreated)) }
                                    </div>
                                </TableCell> 
                                <TableCell align="center">{row.numberOfReports}</TableCell>
                                <TableCell align="center">{row.userCreated}</TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={ this.downloadPackage.bind(this, row.documentId) }>
                                        <DownloadIcon color="primary" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                    </Table>
                    { !this.state.loadFinished ? 
                    <Grid container spacing={3} style={{padding: 40}}>
                      <Grid item xs={12}>
                        <div align="center">
                          <CircularProgress />
                          <Typography component="p" variant="body1" color="primary"> 
                          Подаци се учитавају
                          </Typography>  
                        </div> 
                      </Grid>
                    </Grid>
                      : ""}
                    { this.state.loadFinished && this.state.packages.length === 0 ? 
                    <Grid container spacing={3} style={{padding: 30}}>
                      <Grid item xs={12}>
                        <div align="center">
                          <Typography component="p" variant="body2" color="primary"> 
                          Нема евидентираних IDES пакета у систему за одабрану годину
                          </Typography>  
                        </div> 
                      </Grid>
                    </Grid>
                      : ""}
                    </Paper>
                </Grid>
            </Grid>
            </Container>
            <Snackbar open={this.state.snackbar} 
                autoHideDuration={5000} 
                anchorOrigin={{ vertical:'bottom', horizontal:'right'}}
                onClose={this.closeSnackBar} >
                { !this.state.loadSuccessful ?
                <Alert severity="error" onClose={this.closeSnackBar}>
                    <AlertTitle>Грешка при учитавању листе пакета за одабрану годину</AlertTitle>
                    {this.state.snackbarMessage}
                </Alert>
                : 
                <Alert severity="error" onClose={this.closeSnackBar}>
                    <AlertTitle>Грешка при преузимању одабраног пакета</AlertTitle>
                    {this.state.snackbarMessage}
                </Alert>
                }
            </Snackbar>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(PackageList);