import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, AppBar, Toolbar, Breadcrumbs, Typography, IconButton, Menu, MenuItem, Button } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LanguageIcon from '@material-ui/icons/Language';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Profile from './Profile';
import bgImage from "../../images/zastava.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24,
      },
      appBar: {
        backgroundColor: '#232f3e',
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "round",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      title: {
        flexGrow: 1,
      },
      link: {
        display: 'flex',
      },
      icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
        color: "#aaaaaa",
      },
      menuText: {
        color: "#aaaaaa",
      },
  }));


export default function TopBar(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const accountMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      console.log('Closing menu');
      setAnchorEl(null);
    };

    const logout = () => {
      props.keycloak.logout();
    }

    const classes = useStyles();

    const [open] = React.useState(true);

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="fixed" color="default" elevation={1} className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                { !window.location.pathname.includes("report") && !window.location.pathname.includes("package") ?
                <Breadcrumbs aria-label="breadcrumb" className={ classes.menuText }>
                    <Button color="primary" component={Typography} className={ classes.menuText }>
                        <HomeIcon className={classes.icon} />
                        FATCA
                    </Button>
                </Breadcrumbs>
                    :
                    ""
                }
                { window.location.pathname.includes("reportUpload") ?
                <Breadcrumbs aria-label="breadcrumb">
                    <Button color="inherit" to="/" component={Link} className={classes.menuText}>
                        <HomeIcon className={classes.icon} />
                        FATCA
                    </Button>
                    <Button color="primary" component={Typography} className={classes.menuText}>
                        <DashboardIcon className={classes.icon} />
                        Слање извештаја
                    </Button>
                </Breadcrumbs>
                    :
                    ""
                }
                { window.location.pathname.includes("organizations") ?
                <Breadcrumbs aria-label="breadcrumb">
                    <Button color="inherit" to="/" component={Link} className={classes.menuText}>
                        <HomeIcon className={classes.icon} />
                        План
                    </Button>
                    <Button color="primary" underline="none" component={Typography} className={classes.menuText}>
                        <DashboardIcon className={classes.icon} />
                        Организације
                    </Button>
                </Breadcrumbs>
                    :
                    ""
                }
                { window.location.pathname.includes("codes") ?
                  <Breadcrumbs aria-label="breadcrumb">
                    <Button color="inherit" to="/" component={Link} className={classes.menuText}>
                        <HomeIcon className={classes.icon} />
                        План
                    </Button>
                    <Button color="inherit" underline="none" to="codes" component={Link} className={classes.menuText}>
                        <DashboardIcon className={classes.icon} />
                        Преглед сифарника
                    </Button>

                  </Breadcrumbs>
                    :
                    ""
                }
                <Typography className={classes.title} />
                <IconButton color="inherit">
                    <NotificationsIcon className={classes.menuText}/>
                </IconButton>
                <IconButton color="inherit">
                    <LanguageIcon className={classes.menuText}/>
                </IconButton>
                <Button
                  aria-label="Кориснички налог"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={accountMenu}
                  style={{marginLeft: 20}}
                  color="inherit"
                  className={classes.menuText}
                >
                  <Typography variant="subtitle2" color="inherit">
                    {sessionStorage.getItem("user")}
                  </Typography>
                  <ExpandMore style={{ paddingLeft: 5 }}/>
                </Button>
                <AccountCircleIcon className={classes.menuText}/>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose = {handleClose}
                    onSelect = {handleClose}
                  >
                    <div onClick = {handleClose}>
                      <Profile className={classes.menuText}/>
                    </div>
                    <MenuItem onClick={logout}>Излогуј се</MenuItem>
                </Menu>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
