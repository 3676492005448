import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { get } from 'axios';
import { Container, Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, Typography, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Title from '../common/Title';


const useStyles = theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

class FatcaDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadFinished: false,
            reportsPerYear: [],
            weeklyDynamics: [],
            activities: [],
            loadSuccessful: true,
            snackbar: false,
            snackbarMessage: ' '
        };
    }

    componentDidMount() {
        // Getting reports per year
        get(process.env.REACT_APP_API_URL + "/reportsPerYear", { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
        .then(response => {
          this.setState({
            loadFinished: true,
            reportsPerYear: response.data,
            loadSuccessful: true,
            snackbar: false
          });
        })
        .catch(error => {
          this.setState({
            loadFinished: true,
            loadSuccessful: false,
            snackbar: true,
            snackbarMessage: error.message
          });
          console.error("Failed fetching FATCA yearly statistics through API call");
        });

        // Getting weekly dynamics
        get(process.env.REACT_APP_API_URL + "/weeklyDynamics", { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
        .then(response => {
          this.setState({
            loadFinished: true,
            weeklyDynamics: response.data,
            loadSuccessful: true,
            snackbar: false
          });
        })
        .catch(error => {
          this.setState({
            loadFinished: true,
            loadSuccessful: false,
            snackbar: true,
            snackbarMessage: error.message
          });
          console.error("Failed fetching FATCA weekly statistics through API call");
        });

        // Getting activities
        get(process.env.REACT_APP_API_URL + "/activities?page=0&pageSize=25", { headers: { "Authorization": "Bearer " + sessionStorage.getItem("authentication") } })
        .then(response => {
          this.setState({
            loadFinished: true,
            activities: response.data,
            loadSuccessful: true,
            snackbar: false
          });
        })
        .catch(error => {
          this.setState({
            loadFinished: true,
            loadSuccessful: false,
            snackbar: true,
            snackbarMessage: error.message
          });
          console.error("Failed fetching FATCA reports activities log");
        });

        //set session properties
        sessionStorage.setItem("status","%25");
        sessionStorage.setItem("org","%25");
    }


    closeSnackBar = e => {
      this.setState({
          snackbar: false
      });
    }

    render() {
        const { classes } = this.props;

        const dateTimeOptions = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false
          };

        if (!this.state.loadFinished)
        return(
            <Grid container spacing={3} style={{padding: 40}}>
              <Grid item xs={12}>
                <div align="center">
                  <CircularProgress />
                  <Typography component="p" variant="body1" color="primary">
                  Подаци се учитавају
                  </Typography>
                </div>
              </Grid>
            </Grid>
        )
        else
        return (
            <React.Fragment>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <Title>Број достављених извештаја по годинама</Title>
                                <LineChart width={500} height={300} data={this.state.reportsPerYear}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="XAxis"/>
                                    <YAxis dataKey="value"/>
                                    <Tooltip />
                                    <Line type="monotone" dataKey="value" stroke="#00a" />
                                </LineChart>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <Title>Динамика слања извештаја на недељном нивоу</Title>
                                <BarChart width={500} height={300} data={this.state.weeklyDynamics}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="XAxis"/>
                                    <YAxis dataKey="value"/>
                                    <Tooltip />
                                    <Bar type="monotone" dataKey="value" fill="#44d" />
                                </BarChart>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Title>Активности</Title>
                                <Table size="small">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Модул</TableCell>
                                        <TableCell align="center">Објекат</TableCell>
                                        <TableCell align="center">Активност</TableCell>
                                        <TableCell align="center">Корисник</TableCell>
                                        <TableCell align="center">Датум и време</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.activities.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.auditModule}</TableCell>
                                            <TableCell>{row.auditObject}</TableCell>
                                            <TableCell>{row.auditMessage}</TableCell>
                                            <TableCell>{row.userCreated}</TableCell>
                                            <TableCell align="center">
                                              <div>
                                                  { Intl.DateTimeFormat('sr-Latn-RS', dateTimeOptions).format(new Date(row.dateCreated)) }
                                              </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                { this.state.loadFinished && this.state.activities.length === 0 ?
                                    <div align="center" style={{padding: 30}}>
                                    <Typography component="p" variant="body2" color="primary">
                                    Нема сачуваних активности за вашу финансијску институцију
                                    </Typography>
                                    </div>
                                : ""}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                { this.state.loadSuccessful ? " " :
                <Snackbar open={this.state.snackbar}
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical:'bottom', horizontal:'right'}}
                    onClose={this.closeSnackBar} >
                    <Alert severity="error" onClose={this.closeSnackBar}>
                        <AlertTitle>Грешка при учитавању података са позадинских сервиса</AlertTitle>
                        {this.state.snackbarMessage}
                    </Alert>
                </Snackbar>
                }
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(FatcaDashboard);
